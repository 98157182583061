'use client';

// React & Next
import { useEffect } from 'react';

// 3rd
import { Link } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

// App - Other
import Locale from '@/locale/en.json';
import { DASHBOARD_ROUTES } from '@/config/routes';
import { Error404Illustration } from '@/components/atoms/icon';
import { Button } from '@/components/molecules/button';
import { AppLayout } from '@/components/layouts/app';
import { FlexiblePageLayout } from '@/components/layouts/page';

const locale = Locale.pages['not-found'];

type NotFoundPageProps = {
  error: Error & { digest?: string };
};

export default function NotFoundPage({ error }: NotFoundPageProps) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <AppLayout justifyContent="center">
      <FlexiblePageLayout>
        <FlexiblePageLayout.Content
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap="56px"
          mt="-56px"
        >
          <Error404Illustration maxW="640px" w="auto" h="auto" />

          <Link href={DASHBOARD_ROUTES.dashboard()} ms="56px" _hover={{ textDecoration: 'none' }}>
            <Button variant="old.solid" leftIcon={<ArrowBackIcon />} aria-label={locale['Go home']}>
              {locale['Go home']}
            </Button>
          </Link>
        </FlexiblePageLayout.Content>
      </FlexiblePageLayout>
    </AppLayout>
  );
}
