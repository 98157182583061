export const REVIEWS_ROUTES = {
  root: '/reviews',

  reviews: () => `${REVIEWS_ROUTES.root}`,

  review: (reviewId: string) => `${REVIEWS_ROUTES.reviews()}/${reviewId}`,
  reviewThreats: (reviewId: string) => `${REVIEWS_ROUTES.reviews()}/${reviewId}?tab=threats`,
  reviewRequirements: (reviewId: string) =>
    `${REVIEWS_ROUTES.reviews()}/${reviewId}?tab=requirements`,

  reviewCandidates: () => `${REVIEWS_ROUTES.reviews()}?view=review-candidate-details`,
  reviewCandidate: (candidateId: string) =>
    `${REVIEWS_ROUTES.reviews()}?view=review-candidate-details&id=${candidateId}`,
};
