export const FRAMEWORKS_ROUTES = {
  root: '/frameworks',

  frameworks: () => `${FRAMEWORKS_ROUTES.root}`,

  // TODO below query params usage should be handled in a different way
  uploadFramework: () => `${FRAMEWORKS_ROUTES.frameworks()}?upload=framework`,
  createFramework: () => `${FRAMEWORKS_ROUTES.frameworks()}?create=framework`,
  updateFramework: (frameworkId: string) =>
    `${FRAMEWORKS_ROUTES.frameworks()}?update=${frameworkId}`, // TODO this should be update=framework&id=...

  framework: (frameworkId: string) => `${FRAMEWORKS_ROUTES.frameworks()}/${frameworkId}`,

  createControl: (frameworkId: string) =>
    `${FRAMEWORKS_ROUTES.framework(frameworkId)}?create=control`,
  createRequirement: (frameworkId: string) =>
    `${FRAMEWORKS_ROUTES.framework(frameworkId)}?create=requirement`,
  requirementDetails: (frameworkId: string, requirementId: string) =>
    `${FRAMEWORKS_ROUTES.framework(frameworkId)}?id=${requirementId}`,
};

// export const FRAMEWORKS_QUERY_PARAMS = {
//   requirementDetailsDrawer: 'requirementId',
// };
