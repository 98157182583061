// React & Next
import type { ReactNode } from 'react';

// 3rd
import { Box, Flex } from '@chakra-ui/react';
import type { StyleProps } from '@chakra-ui/react';

type AppLayoutProps = StyleProps & {
  children: ReactNode;
};

export const AppLayout = ({ children, ...styleProps }: AppLayoutProps) => {
  return (
    <Flex direction="column" w="100vw" h="100vh" overflow="hidden" {...styleProps}>
      {children}
    </Flex>
  );
};

type NavbarContainerProps = {
  children: ReactNode;
};

const NavbarContainer = ({ children }: NavbarContainerProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      py="md"
      px="2xl"
      bg="surface.primary"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor="border.primary"
    >
      <Flex alignItems="flex-start" flex="1 0 0" maxW="1560px" py="none" px="3xl">
        {children}
      </Flex>
    </Flex>
  );
};

AppLayout.Navbar = NavbarContainer;

type PageContainerProps = {
  children: ReactNode;
};

const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      overflow="auto"
      bg="surface.secondary"
    >
      <Box h="100%" w="100%" maxW="1560px">
        {children}
      </Box>
    </Flex>
  );
};

AppLayout.Page = PageContainer;
